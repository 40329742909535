/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { gql } from 'apollo-angular';

export interface LastEvaluatedKey {
  userId: string;
  timestamp: number;
}
// eslint-disable-next-line @typescript-eslint/no-unsafe-return
export const getHistories = (count: number, lastEvaluatedKey?: LastEvaluatedKey) => gql`
    query getHistory {
      getHistory(
        input: {
          count: ${count},
          lastEvaluatedKey: {
            timestamp: ${lastEvaluatedKey!.timestamp},
            userId: "${lastEvaluatedKey!.userId}"
          }
        }
      ) {
        lastEvaluatedKey {
          timestamp
          userId
        }
        history {
          resolvedTemplate
          account
          action
          expiration
          service
          timestamp
          userId
          metadata
          readStatus
        }
      }
    }
  `;

export const unseenCountQuery = gql`
  query getUnseenCount {
    getUnseenCount {
      unseenCount
    }
  }
`;
