/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { Environment } from '@s2a/core';

export interface CustomEnvironment extends Environment {
  subAppUrl: string;
  refreshInterval?: number;
  notificationHistoryUrl?: string;
  sentryDsn: string;
  sentryRelease: string;
  componentUrl?: string;
  enableSupportRequest?: boolean;
}

const baseDomain = location.hostname.split('.').slice(1).join('.');

const domain = baseDomain === 'localhost' ? 'dev.share2act.cn' : baseDomain;

const getHistoryEndpoint = (historyDomain: string) => {
  if (historyDomain === 'share2act.cn') {
    // productive
    return 'https://hlsrvxxsxfgffjkkewsp3yf7hy.appsync-api.cn-northwest-1.amazonaws.com.cn/graphql';
  } else if (historyDomain === 'test.share2act.cn') {
    // test
    return 'https://huc2ekuc45go3d5xcwnvuqxkaq.appsync-api.cn-northwest-1.amazonaws.com.cn/graphql';
  }

  return 'https://ibnbsudshjf7vlbuumgnx6s32a.appsync-api.cn-northwest-1.amazonaws.com.cn/graphql';
};

export const environment: CustomEnvironment = {
  production: domain === 'share2act.cn',
  apiUrl: `https://api.${domain}/v1/`,
  iconUrl: `https://icons.${domain}/v2/`,
  imageUrl: `https://assets.${domain}/v12/images/`,
  styleUrl: `https://assets.${domain}/v12/styles/`,
  translationUrl: `https://translations.${domain}/v1/`,
  domainUrl: `https://my.${domain}/`,
  subAppUrl: `https://app.${domain}/`,
  refreshInterval: 60 * 1000, // 1 Min
  notificationHistoryUrl: getHistoryEndpoint(domain),
  sentryDsn: '',
  sentryRelease: 'xxxSENTRY_RELEASExxx' /* <-- will be set at build time */,
  componentUrl: `https://components.${domain}/`,
  enableSupportRequest: false, // disable customer support
};
